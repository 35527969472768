// GA
import ReactGA from 'react-ga4';

// utils
import {lazy, Suspense} from 'react';

// styles
import ThemeStyles from '@styles/theme';
import './style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-grid-layout/css/styles.css';

// contexts
import {SidebarProvider} from '@contexts/sidebarContext';
import {ThemeProvider} from 'styled-components';
import {ModalProvider} from '@contexts/modalContext';

// hooks
import {useThemeProvider} from '@contexts/themeContext';
import React, {useEffect, useRef, useState} from 'react';
import {useWindowSize} from 'react-use';

// components
import {Route, Routes, useLocation} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Loader from '@components/Loader';
import Sidebar from '@components/Sidebar';

const helmetContext = {};
const Dashboard = lazy(() => import('@pages/Dashboard'));
const Spin = lazy(() => import('@pages/Spin'));
const SignIn = lazy(() => import('@pages/SignIn'));

const App = () => {
    const appRef = useRef(null);
    const {theme} = useThemeProvider();
    const {width} = useWindowSize();
    const [isUserExist, setIsUserExist] = useState(false);

    useEffect(() => {
        appRef.current && appRef.current.scrollTo(0, 0);
        const userExist = localStorage.getItem("userExist");
        if(userExist) {
            setIsUserExist(true);
        }
    }, []);

    const gaKey = process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS;
    gaKey && ReactGA.initialize(gaKey);
    const currentRoute = useLocation();
    return (
            <SidebarProvider>
                <ThemeProvider theme={{theme: theme}}>
                    <ThemeStyles/>
                    <ToastContainer theme="colored" autoClose={2000} toastStyle={{borderRadius: 4}}/>
                    <ModalProvider>
                        
                        <div ref={appRef}>
                            {width > 768 && <Sidebar/>}
                            <Suspense fallback={<Loader visible/>}>
                                <Routes>
                                    {
                                        isUserExist ? <>
                                            <Route path="/" element={<Dashboard/>}/>
                                            <Route path="/spin" element={<Spin/>}/>
                                            <Route path="/sign-in" element={<SignIn/>}/>
                                            
                                        </>
                                        : <>
                                            <Route path="/" element={<SignIn/>}/>
                                        </>
                                    }
                                </Routes>
                            </Suspense>
                        </div>
                        
                    </ModalProvider>
                </ThemeProvider>
            </SidebarProvider>
    );
}

export default App;
